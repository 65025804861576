<template>
  <vx-card>
    <div class="vx-col sm:w-1/1 w-full mb-base">
      <vs-tabs>
        <vs-tab label="Journak Monitoring Report">
          <div class="tab-text">
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>OU</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect class="selectExample" v-model="selectedOu" :options="optionOu" :multiple="true"
                  :allow-empty="true" :group-select="false" :max-height="120" :limit="3" placeholder="Type to search"
                  :custom-label="customLabel">
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Posting Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <date-range-picker ref="picker" :opens="center" :singleDatePicker="false" :timePicker="false"
                  :timePicker24Hour="false" :showWeekNumbers="true" :showDropdowns="true" :autoApply="true"
                  v-model="postingDate" class="w-full">
                  <template v-slot:input="picker" style="width: 100%">
                    {{ dateFormat(picker.startDate) }} -
                    {{ dateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-button color="success" @click="handleDrawTable()">Get Data</vs-button>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col md:w-1/1 w-full mb-base">
                <JournalMonitoringTable 
                :uoIDs="this.uoIDs"  
                :startPostingDate="this.postingDate.startDate"  
                :endPostingDate="this.postingDate.endDate"  
                :draw="this.draw" 
                />
              </div>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Export">
          <div class="tab-text">
            <data-table-export></data-table-export>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import JournalMonitoringTable from "./JournalMonitoringTable.vue";
import DataTableExport from "./DataTableExport.vue";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { mapState, mapActions } from "vuex/dist/vuex.common.js";
export default {
  components: {
    JournalMonitoringTable,
    DataTableExport,
    DateRangePicker,
    Datepicker,
  },
  data() {
    return {
      selectedOu: [],
      optionOu: [],
      uoIDs: [],
      draw: 0,
      postingDate: {
        startDate: null,
        endDate: null,
      },
    };
  },
  methods: {
    ...mapActions({ getOu: 'reportJournalMonitoring/getOu' }),
    customLabel(val) {
      if (val) {
        return val.code === "-" ? `${val.name}` : `(${val.code}) ${val.name}`;
      }
    },
    handleClose() {
      this.detail = false;
    },

    handleOpen() {
      this.detail = true;
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("dddd, MMMM Do YYYY");
      }
      return a;
    },
    handleDrawTable() {
      this.draw++;
    },
  },
  mounted() {
    this.getOu();
    this.draw++;
  },
  watch: {
    selectedOu(val) {
      let uoIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          uoIDs.push(e.id);
        }
      });

      this.uoIDs = uoIDs;
    },
    ouList(val){
      this.optionOu = val
    }
  },
  computed: {
    ...mapState({
      ouList: (state) => state.reportJournalMonitoring.ouList,
    }),
  }
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>