<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="exportReport"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="
              p-3
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            "
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <!-- <vs-th></vs-th> -->
        <vs-th
          v-for="(head, index) in this.table.heads"
          :key="index"
          :sort-key="head.field"
          >{{ head.title }}</vs-th
        >
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].created_at">
            {{ dateFormat(data[indextr].created_at) }}
          </vs-td>
          <vs-td :data="data[indextr].created_by">
            {{ data[indextr].created_by }}
          </vs-td>
          <vs-td :data="data[indextr].file_path" @click="handleFilePath(data[indextr].file_path)">
            <div @click="handleFilePath(data[indextr].file_path)">
              {{ data[indextr].file_path }}
            </div>
          </vs-td>
          <vs-td :data="data[indextr].status">
            {{ data[indextr].status }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="exportReportTotalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from "moment";
import { mapState, mapActions } from "vuex/dist/vuex.common.js";
export default {
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    ...mapActions({
      exportJournalMonitoringReport: 'reportJournalMonitoring/exportJournalMonitoringReport',
      downloadJournalMonitoringReport: 'reportJournalMonitoring/downloadJournalMonitoringReport',
    }),
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        heads: [
          {
            field: "created_at",
            title: "Created At",
          },
          {
            field: "created_by",
            title: "Created By",
          },
          {
            field: "file_path",
            title: "File",
          },
          {
            field: "status",
            title: "Status",
          },
        ],
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.$emit("close");
    },
    handleFilePath(data) {
      this.downloadJournalMonitoringReport({ file_name: data }).then(
        () => { }
      )
    },
    getData() {
      this.$vs.loading();
      this.exportJournalMonitoringReport({
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        type: "JournalMonitoringReport",
      }).then(
        (resp) => {
          this.$vs.loading.close();
        }
      )
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      // return moment(val).local();
      return moment(val)
          .add(-7, "h")
          .utc()
          .local();
    },
  },
  watch: {
    detail() {
      this.getData();
    },
    startDate() {
      this.getData();
    },
    endDate() {
      this.getData();
    },
    downloadReport(val) {
      const type = val.data.type
      const url = window.URL.createObjectURL(val.data, {type: type, encoding: 'UTF-8'});
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', val.name);
    
      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();
    }
  },
  computed: {
    ...mapState({
      exportReport: (state) => state.reportJournalMonitoring.exportReport,
      exportReportTotalPage: (state) => state.reportJournalMonitoring.exportReportTotalPage,
      downloadReport: (state) => state.reportJournalMonitoring.downloadReport,
    }),
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>